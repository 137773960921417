import React, { Component } from 'react';
import axios from 'axios';
import { Button } from 'primereact/button';
import { EditorState, ContentState, convertToRaw, Modifier } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { Messages } from 'primereact/messages';
import { SelectButton } from 'primereact/selectbutton';
import { InputTextarea } from 'primereact/inputtextarea';
import DialogAreYouSure from "../dialogs/DialogAreYouSure";
import DialogLeverancierDescriptions from "../dialogs/DialogLeverancierDescriptions";

let styleTextara = {
  width: '100%',
  lineHeight: '1.42',
  padding: '12px 15px',
  fontFamily: 'Helvetica, Arial, sans-serif',
  fontSize: '13px'
};

let languages = [{ label: 'NL', 'value': 'NL' }, { label: 'EN', 'value': 'EN' }];

class ProductDescriptions extends Component {
  state = {
    product: null,
    webshop_lang: 'NL',
    odoo_lang: 'NL',
    waitingForSync: false,
    translate_button_disabled: false,
    editorStateNL: EditorState.createEmpty(),
    editorStateEN: EditorState.createEmpty(),
  };

  onEditorStateChangeNL = (editorStateNL) => {
    this.state.product.description = draftToHtml(convertToRaw(editorStateNL.getCurrentContent()));
    this.setState({ editorStateNL });
  };

  onEditorStateChangeEN = (editorStateEN) => {
    this.state.product.description_en_US = draftToHtml(convertToRaw(editorStateEN.getCurrentContent()));
    this.setState({ editorStateEN });
  };

  handlePastedText = (text, html, editorState) => {
    if (text) {
      const contentState = editorState.getCurrentContent();
      const selectionState = editorState.getSelection();

      const newContentState = ContentState.createFromText(text);

      const contentWithPastedText = Modifier.insertText(
        contentState,
        selectionState,
        text
      );

      const newEditorState = EditorState.push(editorState, contentWithPastedText, 'insert-characters');
      this.setState({ editorStateNL: newEditorState });

      return true;
    }

    return false;
  };

  async componentDidMount() {
    let data = await this.getData();

    if (data.description) {
      this.setState({
        editorStateNL: EditorState.createWithContent(
          ContentState.createFromBlockArray(htmlToDraft(data.description))
        ),
      });
    }

    if (data.description_en_US) {
      this.setState({
        editorStateEN: EditorState.createWithContent(
          ContentState.createFromBlockArray(htmlToDraft(data.description_en_US))
        ),
      });
    }

    this.setState({ product: data });
  }

  render() {
    if (!this.state.product) return null;

    return (
      <div style={{ fontSize: '1rem' }}>
        <DialogAreYouSure ref={this.DialogAreYouSure} />
        <DialogLeverancierDescriptions ref={this.DialogLeverancierDescriptions} />
        {/* Below ugly hack to make something work i dont inderstand */}
        <div className="p-grid">
          <div className="p-col" />
        </div>

        <div className="p-grid">
          <div className="p-col-3">
            <div>Webshop Omschrijving</div>
            <div>
              <SelectButton
                style={{ marginTop: '0.5rem' }}
                value={this.state.webshop_lang}
                options={languages}
                onChange={(e) => this.setState({ webshop_lang: e.value ? e.value : 'NL' })}
              />
            </div>
            <div>
              <Button
                label="Vertaal"
                disabled={this.state.translate_button_disabled}
                icon="pi pi-google"
                className="p-button-success"
                style={{ margin: '0.5rem 0', width: '8rem' }}
                onClick={() => this.translateClicked()}
              />
            </div>
            <div>
              <Button
                label="Leverancier"
                icon="pi pi-images"
                className="p-button-info"
                style={{ margin: '0', width: '10rem' }}
                onClick={() => this.leverancierClicked()}
              />
            </div>
          </div>

          <div className="p-col-9">
            {this.state.webshop_lang === 'NL' && (
              <Editor
                editorState={this.state.editorStateNL}
                onEditorStateChange={this.onEditorStateChangeNL}
                wrapperClassName="demo-wrapper"
                editorClassName="demo-editor"
                handlePastedText={this.handlePastedText}
              />
            )}
            {this.state.webshop_lang === 'EN' && (
              <Editor
                editorState={this.state.editorStateEN}
                onEditorStateChange={this.onEditorStateChangeEN}
                wrapperClassName="demo-wrapper"
                editorClassName="demo-editor"
                handlePastedText={this.handlePastedText}
              />
            )}
          </div>
        </div>

        {/* ====================================================================================== */}
        {/* ============================== Below just Info ======================================= */}
        {/* ====================================================================================== */}
        <div className="p-grid">
          <div className="p-col-3">
            <div>Odoo Omschrijving</div>
            <div>
              <SelectButton
                style={{ marginTop: '0.5rem' }}
                value={this.state.odoo_lang}
                options={languages}
                onChange={(e) => this.setState({ odoo_lang: e.value ? e.value : 'NL' })}
              />
            </div>
          </div>

          <div className="p-col-9">
            {this.state.odoo_lang === 'NL' && (
              <InputTextarea disabled={true}
                style={styleTextara}
                rows={5}
                value={this.state.product.description_short} />
            )}
            {this.state.odoo_lang === 'EN' && (
              <InputTextarea disabled={true}
                style={styleTextara}
                rows={5}
                value={this.state.product.description_short_en_US} />
            )}
          </div>
        </div>

        <div className="p-grid" style={{ marginBottom: '2em' }}>
          <div className="p-col-3"></div>
          <div className="p-col-9">
            <Button label="Save" onClick={() => this.save()} />
          </div>
        </div>

        <div className="p-grid" style={{ marginBottom: '2em' }}>
          <div className="p-col-12">
            <Messages ref={(el) => (this.savemessage = el)}></Messages>
          </div>
        </div>
      </div>
    );
  }

  translateClicked() {
    // Check if EN prod has text already
    let { product } = this.state;

    if (product.description_en_US && (product.description !== product.description_en_US)) {
      this.DialogAreYouSure.current.open(
        `Het product ${this.state.product.display_name} is al vertaald.\n\n` +
        `Door op ja te klikken overscrijf je de reeds bestaande vertaling\n\n` +
        `Weet je dit zeker?\n\n`
        , () => {
          this.doTranslate()
        },
        'Google Translate Product')
    } else {
      this.doTranslate()
    }
  }

  doTranslate() {
    axios.post(process.env.REACT_APP_MTG_BACK + '/translate-html',
      { html: this.state.product.description }).then(res => {
        this.setState({
          product: {
            ...this.state.product,
            description_en_US: res.data.html
          },
          webshop_lang: 'EN',
          translate_button_disabled: this.state.description_en_US !== null
        })

        this.setState({
          editorStateEN: EditorState.createWithContent(
            ContentState.createFromBlockArray(
              htmlToDraft(res.data.html)
            )
          )
        })
      });
  }

  getData() {
    return new Promise(resolve => {
      axios.get(process.env.REACT_APP_MTG_BACK + '/get-product-detail',
        {
          params: { product_id: this.props.product_id }
        }).then(response => {
          resolve(response.data)
        });
    })
  }

  save() {
    let product = this.state.product;
    axios.post(process.env.REACT_APP_MTG_BACK + '/set-product', product).then((res) => {
      if (res.data.msg === 'ok') {
        this.savemessage.show({ severity: 'success', detail: 'Opgeslagen', closable: false });
      } else {
        this.savemessage.show({ sticky: true, severity: 'error', detail: res.data.msg, closable: false });
      }
    }).catch((err) => {
      this.savemessage.show({ sticky: true, severity: 'error', detail: err, closable: false });
    })
  }

}

export default ProductDescriptions
