import React, {Component} from 'react'
import {DataTable} from 'primereact/datatable'
import {Column} from 'primereact/column'
import DialogProductProperties from '../../dialogs/DialogProductProperties'
import DownloadReserveList from '../../download/DownloadReserveList'
import { ProgressSpinner } from 'primereact/progressspinner'
import axios from "axios";
import {Link} from "react-router-dom";

let colorMap = {
  d_green: '#99CC66',
  c_yellow: '#FFFF99',
  b_orange: '#ff9f54',
  a_red: '#CC6666',
}

export class ReserveList extends Component {

  render() {
    if (!this.state.rows.length) return <ProgressSpinner/>;
    return (
      <div>
        <DialogProductProperties ref={this.DialogProductProperties}/>
        

        <div className="p-grid">
          {this.state.product_id &&
          <div className="p-col-6" style={{fontWeight: 'bold', color: '#555'}}>
            <Link to={'/reports/prognose'}>{'<<< ' + this.state.rows[0].pro_name}</Link>
          </div>}

          <div className={'p-col-' + (this.state.product_id ? 6 : 12)}
               style={{textAlign: this.state.product_id ? 'right' : 'left'}}>

            <DownloadReserveList/>

          </div>
        </div>

        <div className="p-grid">
          <div className="p-col-12">
            {/*=======================================================*/}
            <DataTable paginator={true}
                       ref={this.DataTableRef}
                       rows={20}
                       value={this.state.rows}
                       style={{marginTop: '5px'}}>
              {/* ==== Second Col ==== */}
            <Column
                header={
                    <div title="Artikelnaam">
                    Artikelnaam
                </div>
                }
                filter={true}
                filterMatchMode={'contains'}
                filterPlaceholder={'Zoek op ID of naam...'}
                field="name"
                style={{width: '20rem'}}
                />

            <Column
                header={
                    <div title="Prijs">
                    Prijs
                </div>
                }
                field="kostprijs" 
                style={{width: '4rem'}}
                />

            {this.state.warehouses.map((warehouse) => (
                  <Column key={warehouse.id}
                      header={
                      <div title="Magazijn">
                          {warehouse.id === 1 && 'Uitgeest'}
                          {warehouse.id === 66 && 'Alkmaar'}
                      </div>
                      }
                      style={{width: '6rem'}}
                      body={(row) => {
                        if (row.warehouses[warehouse.id]) {
                          return row.warehouses[warehouse.id].qty_available
                        }
                      }}
                  />
                  
            ))}
            {this.state.warehouses.map((warehouse) => (
                 <Column key={warehouse.id}
                      header={
                      <div title="Virtueel">
                          {warehouse.id === 1 && 'Uitgeest Virtueel'}
                          {warehouse.id === 66 && 'Alkmaar Virtueel'}
                      </div>
                      }
                      style={{width: '6rem'}}
                      body={(row) => {
                        if (row.warehouses[warehouse.id]) {
                          return row.warehouses[warehouse.id].virtual_available
                        }
                      }}
                  />
                  
            ))}

            </DataTable>
          </div>
        </div>
      </div>
    );
  }

  state = {
    product_id: null,
    trafficlight: null,
    rows: [],
    warehouses: [],
    job_started: '',
    lock: '',
    runtime: 0,
    status: {
      d_green: true,
      c_yellow: true,
      b_orange: true,
      a_red: true
    }
  }

  constructor(props) {
    super(props);
    this.DialogProductProperties = React.createRef()
    this.DataTableRef = React.createRef()
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.getRows(this.props.match.params.id);
    }
  }

  componentDidMount() {
    this.getRows(this.props.match.params.id)
  }

  /**
   *
   * @param legenda
   * @param e
   *
   * Some shitty code
   */
  legendaClicked(legenda, e) {

    let parent = e.originalEvent.target.parentNode;
    // Dunno why
    if(parent.className === 'p-col-12')
      return;

    let status = this.state.status
    let parentStyle = parent.style
    status[legenda] = e.value

    if(e.value){
      parentStyle.backgroundColor = '#f4f4f4'
    } else {
      parentStyle.backgroundColor = colorMap[legenda]
    }
    // make filter array
    let filter = [];
    for(let name in status){
      if(!status[name]){
        filter.push(name)
      }
    }
    this.DataTableRef.current.filter(filter, 'trafficlight', 'in')
    this.setState({status})
  }

  getRows() {
    axios.get(process.env.REACT_APP_MTG_BACK + '/report-stock?id=1').then(response => {
        console.log(response);
        this.setState({
            rows: response.data.product_stocks,
            warehouses: response.data.warehouses,
        })
    })
  }
}

export default ReserveList
