import React from 'react'
import Menu from '../components/Menu'
import Auth from '../auth/Auth'
import {Fieldset} from "primereact/fieldset";
import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";
import axios from "axios";
import { InputText } from 'primereact/inputtext';

const BE = process.env.REACT_APP_MTG_BACK

class PageServicePoints extends Auth {

  state = {
    data: [],
    wrong: [],
  }

  constructor(props) {
    super(props);
    this.onRowEditCancel = this.onRowEditCancel.bind(this)
    this.onEditorValueChange = this.onEditorValueChange.bind(this)
    this.onRowEditInit = this.onRowEditInit.bind(this)
    this.codeEditor = this.codeEditor.bind(this)
    this.onRowEditSave=this.onRowEditSave.bind(this)
    this.inputTextEditor = this.inputTextEditor.bind(this)
  }

  getServicePoints() {
    let url = BE + '/service-points/all'
    axios.get(url).then(res => {
        this.mapServicePoints(res);
    })
  }

  mapServicePoints(res) {
    let wrong = [];
    let correct = [];
    {res.data.points.map(function(object, i){
        if (object.sp_zip === object.sp_address) {
          wrong.push(object);
        } else {
          correct.push(object);
        }
    })}
    this.setState({
      data:correct,
      wrong:wrong,
    })
  }

  originalRows = {};

  onRowEditInit(e) {
    // console.log(this.state.data);
    this.originalRows[e.index] = {...this.state.data[e.index]};
  }

  onRowEditCancel(e) {
    let points = [...this.state.data];
    points[e.index] = this.originalRows[e.index];
    delete this.originalRows[e.index];
    this.setState({data: points});
  }

  onRowEditSave(e) {
    const { index, data } = e;
    // console.log(data);
    axios.post(BE + '/service-points/save', data).then(res => {
      this.mapServicePoints(res);
    })
  }

  onEditorValueChange(key, props, value) {
    let updatedProducts = [...props.value];
    updatedProducts[props.rowIndex][props.field] = value;
    this.setState({[`${key}`]: updatedProducts});
  }

  inputTextEditor(key, props, field) {
    return <InputText type="text"
                      style={{width:'100%'}}
                      value={props.rowData[key] || ''}
                      onChange={(e) => this.onEditorValueChange(key, props, e.target.value)}/>;
  }

  codeEditor(key, props) {
    return this.inputTextEditor(key, props, 'code');
  }

  componentDidMount() {
    super.componentDidMount();
    this.getServicePoints();
  }

  render() {

    return (
      <div>
        <Menu {...this.props}/>

        <div className="p-col-12">
          <div className="field">
            <label>Service points</label>
          </div>
          <div className="field">
            
          </div>
        </div>
        <Fieldset style={{fontSize: '1.2rem', color: '#4444444'}} legend="Verkeerde Service points">
          <DataTable value={this.state.wrong}
                    paginator={true}
                    rows={15}
                    editMode="row"
                    onRowEditInit={this.onRowEditInit}
                     onRowEditCancel={this.onRowEditCancel}
                     onRowEditSave={this.onRowEditSave}
                     dataKey="sp_id"
                     style={{marginTop: '5px'}}>
            <Column field="sp_id" filter={true} header="ID"/>
            <Column field="sp_name" filter={true} header="Naam" editor={(props) => this.codeEditor('sp_name', props)}/>
            <Column field="sp_address" filter={true} header="Adres" editor={(props) => this.codeEditor('sp_address', props)}/>
            <Column field="sp_zip" filter={true} header="Postcode" editor={(props) => this.codeEditor('sp_zip', props)}/>
            <Column field="sp_city" filter={true} header="Plaats" editor={(props) => this.codeEditor('sp_city', props)}/>
            <Column field="sp_country" filter={true} header="Land" editor={(props) => this.codeEditor('sp_country', props)}/>
            <Column field="sp_house_number" filter={true} header="Huisnummer" editor={(props) => this.codeEditor('sp_house_number', props)}/>
            <Column rowEditor headerStyle={{width: '7rem'}} bodyStyle={{textAlign: 'center'}}/>
          </DataTable>
        </Fieldset>
        <Fieldset style={{fontSize: '1.2rem', color: '#4444444'}} legend="Service points">
          <DataTable value={this.state.data}
                    paginator={true}
                    rows={15}
                    editMode="row"
                    onRowEditInit={this.onRowEditInit}
                     onRowEditCancel={this.onRowEditCancel}
                     onRowEditSave={this.onRowEditSave}
                     dataKey="sp_id"
                     style={{marginTop: '5px'}}>
            <Column field="sp_id" filter={true} header="ID"/>
            <Column field="sp_name" filter={true} header="Naam" editor={(props) => this.codeEditor('sp_name', props)}/>
            <Column field="sp_address" filter={true} header="Adres" editor={(props) => this.codeEditor('sp_address', props)}/>
            <Column field="sp_zip" filter={true} header="Postcode" editor={(props) => this.codeEditor('sp_zip', props)}/>
            <Column field="sp_city" filter={true} header="Plaats" editor={(props) => this.codeEditor('sp_city', props)}/>
            <Column field="sp_country" filter={true} header="Land" editor={(props) => this.codeEditor('sp_country', props)}/>
            <Column field="sp_house_number" filter={true} header="Huisnummer" editor={(props) => this.codeEditor('sp_house_number', props)}/>
            <Column rowEditor headerStyle={{width: '7rem'}} bodyStyle={{textAlign: 'center'}}/>
          </DataTable>
        </Fieldset>
      </div>
    )
  }


}

export default PageServicePoints
