import React, {Component} from 'react'
import axios from "axios";
import {Button} from "primereact/button";
import {Fieldset} from "primereact/fieldset";
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import { InputText } from 'primereact/inputtext';

const BE = process.env.REACT_APP_MTG_BACK



export class Poll extends Component {

  render() {

    return (

      <div className="p-fluid" style={{marginTop: '1rem'}}>
        <Fieldset legend="Creëer je eigen vraagstelling / stemmen">
          <DataTable value={this.state.poll_list}
                    editMode="row"
                    dataKey="id"
                    paginator={true}
                    rows={15}
                    editable={true}
                    scrollHeight="600px"
                    onRowEditInit={this.onRowEditInit}
                    onRowEditCancel={this.onRowEditCancel}
                    onRowEditSave={this.onRowEditSave}
                    style={{marginTop: '5px'}}>

            <Column field="id" style={{width: '45px'}} header="ID" filter={false} filterMatchMode={'contains'}/>
            <Column field="question" header="Vraag" filter={true} filterMatchMode={'contains'}  editor={(props) => this.codeEditor('question', props)}/>
            <Column field="poll_votes_count" style={{width: '100px'}} header="# Votes" />
            <Column rowEditor headerStyle={{width: '7rem'}} bodyStyle={{textAlign: 'center'}}/>
            {/*DELETE*/}
            <Column
              style={{textAlign: 'center', width: '3rem'}}
              body={(row) => <i className="pi pi-times-circle"
                                onClick={() => {
                                  if(!row.id) return
                                  axios.delete(BE + '/tinycms-remove-question', {params: {id: row.id, webshop_id: row.webshop_id}}).then(() => {
                                    this.loadData()
                                  })
                                }}
                                style={{fontSize: '1.5rem', color: row.id ? 'crimson' : 'transparent'}}/>}/>
          </DataTable>
        </Fieldset>
        <Fieldset legend="Vraag toevoegen" style={{marginTop: '1rem'}}>
          <form onSubmit={this.saveQuestion.bind(this)}>
            <div className="p-field p-grid">
              <label className='p-col-1'>Vraag</label>
              <div className='p-col-4'>
                <InputText id="question" type="text"
                  placeholder="Vraag"
                  value={this.state.question}
                  onChange={e => this.itemChanged(e)}
                />
              </div>
            </div>
            <div className="p-field p-grid">
              <div className='p-col-2'>
                <Button label="Toevoegen" type="submit"/>
              </div>
            </div>
            {this.state.message &&
              <div className="p-message p-component p-message-success p-message-enter-done">
                <div className="p-message-wrapper">
                  <span className="p-message-icon pi  pi-check"></span>
                  <span className="p-message-detail">Vraag toegevoegd</span>
                </div>
              </div>
            }
          </form>
        </Fieldset>
      </div>

    );
  }

  state = {
    webshop_id: null,
    poll_list: [],
    question: '',
  }

  constructor(props) {
    super(props);
    this.onRowEditCancel = this.onRowEditCancel.bind(this)
    this.onEditorValueChange = this.onEditorValueChange.bind(this)
    this.onRowEditInit = this.onRowEditInit.bind(this)
    this.codeEditor = this.codeEditor.bind(this)
    this.onRowEditSave=this.onRowEditSave.bind(this)
    this.inputTextEditor = this.inputTextEditor.bind(this)
  }

  componentDidMount() {
    this.loadData()
  }

  /**
   *  Inline Editor
   */
  originalRows = {};

  handleCheck(val) {
      return this.state.poll_selected.some(item => val.id === item.id);
  }

  itemChanged(e) {
    let newState = {}
    switch (e.target.type) {
      case 'checkbox':
        newState[e.target.id] = e.target.checked
        break;
      default:
        newState[e.target.id] = e.target.value
        break;
    }
    this.setState(newState);
  }

  saveQuestion(e) {
    e.preventDefault();
    this.setState({message: false});
    let data = {};
    data.question = this.state.question;
    data.webshop_id = this.state.webshop_id;

    axios.post(BE + '/tinycms-add-question', data).then(res => {
      this.setState({poll_list: res.data.list})
      this.setState({message: true});
      this.setState({question: ''})
    }).catch(() => {
      alert("\nEr is iets fout gegaan!\n\nHerlaadt de pagina en probeer nogmaals.")
    });
  }

  onEditorValueChange(productKey, props, value) {
    let updatedRows = [...props.value];
    updatedRows[props.rowIndex][props.field] = value;
    this.setState({poll_list: updatedRows});
  }

  onRowEditInit(e) {
    this.originalRows[e.index] = {...this.state.poll_list[e.index]};
  }

  onRowEditSave(e) {
    const { data } = e;
    axios.post(BE + '/tinycms-save-question', {poll_id: data.id, question: data.question, webshop_id: this.state.webshop_id}).then(res => {
      this.setState({poll_list: res.data.list})
      console.log(res.data);
    }).catch(() => {
      alert("\nEr is iets fout gegaan!\n\nHerlaadt de pagina en probeer nogmaals.")
    });
  }

  inputTextEditor(productKey, props, field) {
    return <InputText type="text"
                      style={{width:'100%'}}
                      value={props.rowData[field] || ''}
                      onChange={(e) => this.onEditorValueChange(productKey, props, e.target.value)}/>;
  }

  codeEditor(productKey, props) {
    return this.inputTextEditor(productKey, props, productKey);
  }

  onRowEditCancel(e) {
    let rows = [...this.state.poll_list];
    rows[e.index] = this.originalRows[e.index];
    delete this.originalRows[e.index];
    this.setState({poll_list: rows});
  }

  loadData(){
    // Data
    axios.get(BE + '/tinycms-get-settings?webshop_id=' + this.props.match.params.webshop_id).then(res => {
      this.setState({
        poll_list: res.data.poll,
        webshop_id: res.data.webshop_id
      });
    })
  }
}

export default Poll
