import React from 'react'
import Menu from '../components/Menu'
import Auth from '../auth/Auth'
import {Fieldset} from "primereact/fieldset";
import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";
import axios from "axios";
import { Dropdown } from 'primereact/dropdown';
import * as JaNee from '../enum/ja_nee';

const BE = process.env.REACT_APP_MTG_BACK

class PageOrders extends Auth {

  state = {
    data: [],
    webshoporder: false,
    webshoporders: [],
  }

  getWebshopOrders() {
    let url = BE + '/order/get-webshop-orders'
    axios.get(url).then(res => {
      this.setState({
        webshoporders: res.data.webshoporders
      })
    });
  }

  componentDidMount() {
    super.componentDidMount();
    this.getWebshopOrders();
  }

  searchWebshopOrder(value) {
    this.setState({webshoporder: value})

    let url = BE + '/order/all'
    url += '?webshoporder='+value

    axios.get(url).then(res => {
        this.setState({
          data: res.data.orders,
        })
    })

  }

  mapOrderLines(items) {

    return <DataTable value={items} dataKey="local_id">
      <Column style={{width:'30px'}} field='count' header="#"/>
      <Column style={{width:'50%'}} field='display_name' header="Product"/>
      <Column field='odoo_id' header="OID"/>
      <Column field='odoo_order_id' header="OOID"/>
      <Column field='price' header="Price"/>
      <Column field='price_in_eco' header="ECO"/>
    </DataTable>;

  }

  render() {

    return (
      <div>
        <Menu {...this.props}/>

        <div className="p-col-12">
          <div className="field">
            <label>Webshop</label>
            <Dropdown value={this.state.webshoporder} options={this.state.webshoporders} onChange={(e) => {
              this.searchWebshopOrder(e.target.value);
            }}/>
          </div>
          <div className="field">
            
          </div>
        </div>
        <Fieldset style={{fontSize: '1.2rem', color: '#4444444'}} legend="Orders">
          <div className='help' style={{'float':'right', 'font-size':'12px'}}>
            OID = odoo_id, OOID = odoo_order_id
          </div>
          <DataTable value={this.state.data}
                     dataKey="local_id"
                     style={{marginTop: '5px'}}>
            <Column style={{width:'100px'}} field="local_id" header="Local id"/>
            <Column style={{width:'100px'}} field="local_shop_modus" header="Shopmodus" body={(props) => {
              return JaNee.lookup(props.local_shop_modus)
            }}/>
            <Column field="gebruiker.private_email" header="Email"/>
            <Column field="orderlines" header="Order lines" style={{width:'60%'}}
              body={(props) => {
                return this.mapOrderLines(props.orderlines)
              }}
            />
          </DataTable>
        </Fieldset>
      </div>
    )
  }


}

export default PageOrders
