import React, {Component} from 'react'
import {DataTable} from 'primereact/datatable'
import {Column} from 'primereact/column'
import {ToggleButton} from 'primereact/togglebutton'
import DialogProductProperties from '../../dialogs/DialogProductProperties'
import DownloadPrognose from '../../download/DownloadPrognose'
import {lookup as showOnWebshopLookup} from '../../../enum/show_on_webshop'
import {lookup as jaNeeLookup} from '../../../enum/ja_nee'
import axios from "axios";
import {Link} from "react-router-dom";

let textRight = {textAlign: 'right'}
let textCenter = {textAlign: 'center'}
let colorMap = {
  d_green: '#99CC66',
  c_yellow: '#FFFF99',
  b_orange: '#ff9f54',
  a_red: '#CC6666',
}
let legenda = {
  padding: '2px 8px',
  marginLeft: '8px',
  border: 'solid 1px #ccc',
  fontSize: '0.8rem',
  borderRadius: '0.3rem',
  cursor: 'pointer'
}

export class Prognose extends Component {

  render() {
    if (!this.state.rows.length) return null;
    return (
      <div>
        <DialogProductProperties ref={this.DialogProductProperties}/>
        {/* Below ugly hack to make something work i dont inderstand */}
        <div className="p-grid">
          <div className="p-col"/>
        </div>

        <ToggleButton offLabel="Webshop prognose"
          onLabel="Orders prognose"
          checked={this.state.by_orders}
          onChange={(e) => {
            this.byOrdersClicked(e)
          }}
          style={{marginLeft: '8px'}}/>

        <div className="p-grid">
          {this.state.product_id &&
          <div className="p-col-6" style={{fontWeight: 'bold', color: '#555'}}>
            <Link to={'/reports/prognose'}>{'<<< ' + this.state.rows[0].pro_name}</Link>
          </div>}

          <div className={'p-col-' + (this.state.product_id ? 6 : 12)}
               style={{textAlign: this.state.product_id ? 'right' : 'left'}}>

            {(!this.state.product_id || this.state.trafficlight === 'd_green') &&
            <ToggleButton offLabel="Leverbaar"
                          onLabel="Leverbaar"
                          className="mtg-reports-prognose"
                          checked={this.state.status.d_green}
                          onChange={(e) => {this.legendaClicked('d_green',e)}}
                          style={{...legenda}}/>
            }
            {(!this.state.product_id || this.state.trafficlight === 'c_yellow') &&
            <ToggleButton offLabel="Leverbaar maar geen voorraad"
                          onLabel="Leverbaar maar geen voorraad"
                          className="mtg-reports-prognose"
                          onChange={(e) => {this.legendaClicked('c_yellow',e)}}
                          checked={this.state.status.c_yellow}
                          style={{...legenda}}/>
            }
            {(!this.state.product_id || this.state.trafficlight === 'b_orange') &&
            <ToggleButton offLabel="Momenteel niet leverbaar"
                          onLabel="Momenteel niet leverbaar"
                          className="mtg-reports-prognose"
                          onChange={(e) => {this.legendaClicked('b_orange',e)}}
                          checked={this.state.status.b_orange}
                          style={{...legenda}}/>
            }
            {(!this.state.product_id || this.state.trafficlight === 'a_red') &&
            <ToggleButton offLabel="Niet meer leverbaar"
                          onLabel="Niet meer leverbaar"
                          className="mtg-reports-prognose"
                          onChange={(e) => {this.legendaClicked('a_red',e)}}
                          checked={this.state.status.a_red}
                          style={{...legenda}}/>
            }
            {!this.state.product_id &&
            <DownloadPrognose product_id="" by_orders={this.checkByOrders(this.state.by_orders)}/>
            }
          </div>
        </div>

        <div className="p-grid">
          <div className="p-col-12">
            {/*=======================================================*/}
            <DataTable paginator={true}
                       ref={this.DataTableRef}
                       rows={20}
                       value={this.state.rows}
                       style={{marginTop: '5px'}}>
              {/* ==== Second Col ==== */}
              {!this.state.product_id ?
                <Column
                  header={
                    <div title="Artikelnaam">
                      Artikelnaam
                    </div>
                  }
                  field="pro_name"
                  filter={true}
                  filterMatchMode={'contains'}
                  filterPlaceholder={'Zoek op ID of naam...'}
                  body={(row) => {
                    return <span style={{cursor: 'pointer'}} onClick={() => {
                      this.DialogProductProperties.current.open(
                        row.id,
                        row.pro_name
                      )
                    }}>{row.pro_name}</span>
                  }}/>
                : <Column
                  header={
                    <div title="Webshopnaam">
                      Webshopnaam
                    </div>
                  }
                  field="wso_name"
                  filter={true}
                  filterMatchMode={'contains'}
                  filterPlaceholder={'Zoek op ID of naam...'}
                  body={(row) =>
                    <Link to={'/webshop/' + row.wso_id}>
                      {row.wso_name}
                    </Link>
                  }/>
              }
              <Column header={<span title="Verkocht">Sold</span>}
                      field="sum_product_ordered" style={{...textRight, width: "5rem"}} sortable/>
              {/* ==== Fisrt Col ==== */}
              {!this.state.product_id &&
              <Column header={<span title="# WS"># WS</span>}
                      field="count_webshops"
                      sortable
                      body={(row) =>
                        <Link to={'/reports/prognose/' + row.id}>
                          <div style={{width: '100%'}}>{row.count_webshops}</div>
                        </Link>
                      }
                      style={{...textRight, width: '5.5rem', fontWeight: 'bold'}}/>}
              <Column header={<span title="Niet-verzilverd">Free</span>}
                      field="vouchers_full"
                      headerStyle={{...textCenter, width: "5.5rem"}}
                      style={{...textRight}}/>
              <Column header={<span title="Verzilverd">Used</span>}
                      field="vouchers_used"
                      headerStyle={{...textCenter, width: "5.5rem"}}
                      style={{...textRight}}/>
              <Column header={<span title="Verwacht">Verwacht</span>}
                      field="prognose"
                      headerStyle={{...textCenter, width: "5.5rem"}}
                      style={textRight}/>
              <Column header={<span title="Virt_voorraad">Stock (V)</span>}
                      field="local_calculated_stock"
                      headerStyle={{...textCenter, width: "5.5rem"}}
                      style={textRight}/>
              <Column field="prediction"
                      header={<span title="Prognose">Prognose</span>}
                      sortable
                      headerStyle={{...textCenter, width: '8rem'}}
                      body={(row) => {
                        return <div style={{
                          backgroundColor: row.prediction < 0 ? '#cc6666' : '#fff',
                          width: '100%',
                          textAlign: 'right',
                          borderRadius: '0.3rem',
                          padding: '0px 4px',
                          border: 'solid 1px #ccc'
                        }}>
                          {row.prediction}
                        </div>
                      }}/>

              <Column
                header={<span title="Toon">Toon</span>}
                sortable
                field="show_on_webshop"
                body={(row) => showOnWebshopLookup(row.show_on_webshop)}
                style={{...textCenter, width: "7rem"}}/>

              <Column header={<span title="Lim_Ed">Lim_Ed</span>}
                      field="limited_edition"
                      body={(row) => {
                        return jaNeeLookup(row.limited_edition)
                      }}
                      style={{...textCenter, width: "4.5rem"}}/>
              <Column header={<span title="Status">Status</span>}
                      sortable
                      field="trafficlight"
                      body={(row) => {
                        return <div style={{
                          backgroundColor: colorMap[row.trafficlight],
                          borderRadius: '0.5rem',
                          border: 'solid 1px #ccc',
                          margin: 'auto',
                          width: '1.0rem',
                          height: '1.0rem'

                        }}>&nbsp;</div>
                      }}
                      style={{...textCenter, width: "6rem"}}/>

            </DataTable>
          </div>
        </div>
      </div>
    );
  }

  state = {
    product_id: null,
    trafficlight: null,
    rows: [],
    job_started: '',
    lock: '',
    runtime: 0,
    by_orders: 0,
    status: {
      d_green: true,
      c_yellow: true,
      b_orange: true,
      a_red: true
    }
  }

  constructor(props) {
    super(props);
    this.DialogProductProperties = React.createRef()
    this.DataTableRef = React.createRef()
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.getRows(this.props.match.params.id);
    }
  }

  componentDidMount() {
    this.getRows(this.props.match.params.id)
  }

  /**
   *
   * @param legenda
   * @param e
   *
   * Some shitty code
   */
  legendaClicked(legenda, e) {

    let parent = e.originalEvent.target.parentNode;
    // Dunno why
    if(parent.className === 'p-col-12')
      return;

    let status = this.state.status
    let parentStyle = parent.style
    status[legenda] = e.value

    if(e.value){
      parentStyle.backgroundColor = '#f4f4f4'
    } else {
      parentStyle.backgroundColor = colorMap[legenda]
    }
    // make filter array
    let filter = [];
    for(let name in status){
      if(!status[name]){
        filter.push(name)
      }
    }
    this.DataTableRef.current.filter(filter, 'trafficlight', 'in')
    this.setState({status})
  }

  byOrdersClicked(e) {
    this.setState({by_orders: e.value})
    this.getRows(this.props.match.params.id)
  }

  checkByOrders(val)
  {
    if (val === true) {
      return 1;
    } else {
      return 0;
    }
  }

  getRows(product_id) {
    if (product_id) {
      axios.get(process.env.REACT_APP_MTG_BACK + '/report-prognose-on-product?id=' + product_id + '&by_orders='+this.checkByOrders(this.state.by_orders)).then(response => {
        this.setState({
          product_id: product_id,
          trafficlight: response.data[0].trafficlight,
          rows: response.data,
        })
      })
    } else {
      axios.get(process.env.REACT_APP_MTG_BACK + '/report-prognose-all?by_orders='+this.checkByOrders(this.state.by_orders)).then(response => {
        this.setState({
          product_id: null,
          trafficlight: null,
          rows: response.data,
        })
      })
    }
  }
}

export default Prognose
